import { useContext } from "react";
import { LuxeContext } from "../../context/LuxeProvider";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import * as Styles from "./styles.module.scss";

function MembershipCredit({ scrollToMembershipDetails }) {
  const { luxe } = useContext(LuxeContext);
  const { isMobile } = useDeviceDetect();

  return (
    <div className={Styles.membership_credit}>
      <h2 className="h2 h2--bold h2--uc m-0 mb-20">Membership credit</h2>

      <div className={Styles.membership_credit_content}>
        <p className="h3 h3--bold mb-20">${luxe.subscriptionCredit} credit</p>
        <p className="subtext">Make sure you’re logged in and apply your credit at checkout!</p>
      </div>

      {isMobile ? (
        <div
          className={`${Styles.membership_credit_scroll} subtext`}
          onClick={() => scrollToMembershipDetails()}
          aria-hidden="true"
        >
          Manage Membership
        </div>
      ) : null}
    </div>
  );
}

export default MembershipCredit;
