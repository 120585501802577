// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--545a1";
export var actions_container = "styles-module--actions_container--ea53c";
export var actions_container_buttons = "styles-module--actions_container_buttons--94749";
export var actions_container_content = "styles-module--actions_container_content--7173b";
export var actions_container_content_text = "styles-module--actions_container_content_text--f5511";
export var actions_container_content_title = "styles-module--actions_container_content_title--ccb87";
export var container = "styles-module--container--9fd84";
export var memberStatus = "styles-module--member-status--4e6fb";
export var memberTag = "styles-module--member-tag--576d6";
export var section = "styles-module--section--9ea2e";