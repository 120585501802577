import { useState, useCallback, useContext, forwardRef } from "react";
import axios from "axios";

import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { LuxeContext } from "../../context/LuxeProvider";
import { useTracking } from "../../context/Tracking";
import { useAuth } from "../../context/AuthProvider";

import SkipSubscriptionModal from "./membershipDetails/SkipSubscriptionModal";
import ReactivateSubscriptionModal from "./membershipDetails/ReactivateSubscriptionModal";
import PauseSubscriptionModal from "./membershipDetails/PauseSubscriptionModal";

import { ALLink, ALButton } from "../ALComponents";

import * as Styles from "./membershipDetails/styles.module.scss";

// eslint-disable-next-line no-empty-pattern
const MembershipDetails = forwardRef(({}, ref) => {
  const { accountDetails } = useAuth();
  const { luxe, updateSubscriptionStatus, updateSubscriptionNextBillingDate } =
    useContext(LuxeContext);
  const { trackSubscriptionSkip, trackSubscriptionReactivate, trackSubscriptionPause } =
    useTracking();
  const { sendReport } = useALError();
  const subscription = luxe?.customerSubscription;

  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);

  const showSkipModal = () => {
    setIsSkipModalOpen(true);
  };

  const showReactivationModal = () => {
    setIsReactivateModalOpen(true);
  };

  const onPauseSubscription = useCallback(
    async (timeToPause) => {
      setIsPauseModalOpen(true);

      try {
        const res = await axios.post(
          "/.netlify/functions/subscription",
          {},
          {
            params: {
              cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
              email: accountDetails.email,
              action: "PAUSE",
              period: timeToPause,
            },
          }
        );

        if (res.status === 200) {
          const pausedSubscription = await axios.get(`/.netlify/functions/subscription`, {
            params: {
              cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
              email: accountDetails.email,
            },
          });
          if (pausedSubscription.status === 200) {
            updateSubscriptionNextBillingDate(pausedSubscription.data.membership.next_billing_date);
            updateSubscriptionStatus(pausedSubscription.data.membership.status);
            trackSubscriptionPause(timeToPause);
          }
        }
      } catch (e) {
        sendReport(e, {
          name: "MembershipDetailsSkipSubscription",
          priority: "P3",
        });
      }
    },
    [
      accountDetails,
      sendReport,
      trackSubscriptionPause,
      updateSubscriptionStatus,
      updateSubscriptionNextBillingDate,
    ]
  );

  const onSkipSubscription = useCallback(async () => {
    try {
      const res = await axios.post(
        `/.netlify/functions/subscription`,
        {},
        {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
            action: "PAUSE",
            period: "1",
          },
        }
      );

      if (res.status === 200) {
        const skippedSubscription = await axios.get(`/.netlify/functions/subscription`, {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
          },
        });
        if (skippedSubscription.status === 200) {
          updateSubscriptionNextBillingDate(skippedSubscription.data.membership.next_billing_date);
          updateSubscriptionStatus(skippedSubscription.data.membership.status);
          trackSubscriptionSkip();
        }
      }
    } catch (e) {
      sendReport(e, {
        name: "MembershipDetailsSkipSubscription",
        priority: "P3",
      });
    }
  }, [
    accountDetails,
    sendReport,
    trackSubscriptionSkip,
    updateSubscriptionStatus,
    updateSubscriptionNextBillingDate,
  ]);

  const onReactivateSubscription = useCallback(async () => {
    try {
      const res = await axios.post(
        `/.netlify/functions/subscription`,
        {},
        {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
            action: "REACTIVATE",
          },
        }
      );
      if (res.status === 200) {
        const updatedSubscription = await axios.get(`/.netlify/functions/subscription`, {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
          },
        });
        if (updatedSubscription.status === 200) {
          updateSubscriptionNextBillingDate(updatedSubscription.data.membership.next_billing_date);
          updateSubscriptionStatus(updatedSubscription.data.membership.status);
          trackSubscriptionReactivate();
        }
      }
    } catch (e) {
      sendReport(e, {
        name: "MembershipDetailsReactivateSubscription",
        priority: "P3",
      });
    }
  }, [
    accountDetails,
    sendReport,
    trackSubscriptionReactivate,
    updateSubscriptionStatus,
    updateSubscriptionNextBillingDate,
  ]);

  return (
    <div ref={ref}>
      <h2 className="h2 h2--bold h2--uc m-0 mb-20">Membership Details</h2>
      <div className={Styles.container}>
        <div className={Styles.section}>
          <div className="mb-15">
            <span className="text text--bold">Membership Type</span>
            <span className={`${Styles.memberTag} microtext microtext--bold`}>AL Luxe Member</span>
          </div>
          <div className="mb-15">
            <span className="text text--bold">Membership Status</span>
            <span className={`${Styles.memberStatus} text`}>
              {subscription.status.toLowerCase()}
            </span>
          </div>
          <div>
            <span className="text text--bold">Member Since</span>
            <span className="text">{subscription.member_since}</span>
          </div>
        </div>

        <div className={Styles.section}>
          <div>
            <span className="text text--bold">Next payment</span>
            <span className="text" data-testid="subscription-next-payment">
              {subscription.next_billing ? subscription.next_billing : "-"}
            </span>
          </div>
        </div>

        <div className={Styles.actions}>
          {subscription.status === "ACTIVE" && (
            <div className={Styles.actions_container}>
              <div className={Styles.actions_container_content}>
                <p className={`${Styles.actions_container_content_title} h4 h4--bold`}>
                  SKIP MEMBERSHIP
                </p>
                <p className={`${Styles.actions_container_content_text} subtext`}>
                  Need a quick break? Skip one month and you'll automatically restart the next
                  month.
                </p>
              </div>
              <div className={Styles.actions_container_buttons}>
                <ALButton size="medium" variant="primary" onClick={showSkipModal}>
                  Skip this month
                </ALButton>
              </div>
            </div>
          )}

          {subscription.status === "ACTIVE" && (
            <div className={Styles.actions_container}>
              <div className={Styles.actions_container_content}>
                <p className={`${Styles.actions_container_content_title} h4 h4--bold`}>
                  PAUSE OR CANCEL MEMBERSHIP
                </p>
                <p className={`${Styles.actions_container_content_text} subtext`}>
                  Press pause for up to 3 months - you'll be charged again as soon as you're ready
                  to restart.
                  <br />
                  <br />
                  We'll miss you but understand some things don't last forever. Just be sure to
                  cancel 24 hours before your next charge.
                </p>
              </div>
              <div className={Styles.actions_container_buttons}>
                <ALButton size="large" variant="primary" onClick={() => setIsPauseModalOpen(true)}>
                  Pause membership
                </ALButton>
                <ALLink to="/ana-luisa-luxe/cancel">
                  <ALButton size="large" variant="primary">
                    Cancel membership
                  </ALButton>
                </ALLink>
              </div>
            </div>
          )}

          {(subscription.status === "PAUSED" || subscription.status === "CANCELLED") && (
            <ALButton size="medium" variant="secondary" onClick={showReactivationModal}>
              Reactivate membership
            </ALButton>
          )}
        </div>
      </div>

      <SkipSubscriptionModal
        isOpen={isSkipModalOpen}
        subscriptionStatus={subscription.status}
        onAction={onSkipSubscription}
        onClose={() => setIsSkipModalOpen(false)}
      />

      <ReactivateSubscriptionModal
        isOpen={isReactivateModalOpen}
        subscriptionStatus={subscription.status}
        onAction={onReactivateSubscription}
        onClose={() => setIsReactivateModalOpen(false)}
      />

      <PauseSubscriptionModal
        isOpen={isPauseModalOpen}
        onAction={onPauseSubscription}
        onClose={() => setIsPauseModalOpen(false)}
      />
    </div>
  );
});

export default withALErrorBoundary({
  name: "MembershipDetails",
  priority: "P3",
})(MembershipDetails);
