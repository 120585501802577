import { useEffect, useContext, useRef, useCallback } from "react";
import { navigate } from "gatsby";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { LuxeContext } from "../context/LuxeProvider";
import { useAuth } from "../context/AuthProvider";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { isBrowser } from "../context/helpers";

import ALRewardsHeader from "./ALRewardsHeader";
import ALRewardsNavigation from "./ALRewardsNavigation";
import MembershipCredit from "./luxe/MembershipCredit";
import BillingHistory from "./luxe/BillingHistory";
import MembershipDetails from "./luxe/MembershipDetails";
import FaqLuxe from "./FaqLuxe";
import RedeemPoints from "./RedeemPoints";

import * as Styles from "./luxe/styles.module.scss";

function Luxe() {
  const { luxe } = useContext(LuxeContext);
  const { isMobile } = useDeviceDetect();
  const { accountDetails } = useAuth();
  const refMembershipDetailsContainer = useRef(null);

  if (!accountDetails && isBrowser) {
    navigate("/?auth=login");
  }

  const subscription = luxe?.customerSubscription;

  useEffect(() => {
    if ((!luxe?.isLuxeEnabled || !luxe?.customerSubscription) && luxe.accountDetailsLoaded) {
      navigate("/account");
    }
  }, [luxe]);

  const scrollToMembershipDetails = useCallback(() => {
    if (isBrowser && refMembershipDetailsContainer.current) {
      const diffPixel = isMobile ? 65 : 100;
      const y =
        refMembershipDetailsContainer.current.getBoundingClientRect().top +
        window.pageYOffset -
        diffPixel;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [isMobile]);

  if (!luxe?.isLuxeEnabled || !subscription) {
    return null;
  }

  return (
    <>
      {isMobile && <ALRewardsNavigation />}
      <ALRewardsHeader type="luxe" />
      <div className={Styles.container}>
        {!isMobile && <ALRewardsNavigation />}
        <MembershipCredit scrollToMembershipDetails={scrollToMembershipDetails} />
        <BillingHistory />
        <MembershipDetails ref={refMembershipDetailsContainer} />
        <RedeemPoints type="luxe" />
        <FaqLuxe />
      </div>
    </>
  );
}

export default withALErrorBoundary({
  name: "Luxe",
  priority: "P2",
})(Luxe);
